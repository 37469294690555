import { Component } from '@angular/core';
import { SharedModuleModule } from '../shared-module/shared-module.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Welcome to Crismonic Wave Innovations';
  subTitle = 'Simple, Powerful, Innovative Technology Solutions';

  panelOpenState = true;

  setStep() {
    this.panelOpenState = !this.panelOpenState;
  }

}


import { NgModule } from '@angular/core';
import { MatButtonModule,
         MatCheckboxModule,
         MatGridListModule,
         MatExpansionModule,
         MatFormFieldModule,
         MatInputModule} from '@angular/material';

@NgModule({
  imports: [MatButtonModule, MatCheckboxModule, MatGridListModule, MatExpansionModule, MatFormFieldModule, MatInputModule],
  exports: [MatButtonModule, MatCheckboxModule, MatGridListModule, MatExpansionModule, MatFormFieldModule, MatInputModule],
})
export class SharedModuleModule { }
